import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

const YearSelect = ({
  id,
  name,
  placeholderText,
  minYear,
  maxYear,
  ...selectProps
}) => {
  const items = useMemo(() => {
    const items = [{ name: placeholderText, value: null }];
    for (let i = maxYear; i >= minYear; i--)
      items.push({ name: i.toString(), value: i });

    return items;
  }, [placeholderText, minYear, maxYear]);

  return (
    <Select
      items={items}
      id={id && `${id}_year`}
      name={name && `${name}_year`}
      {...selectProps}
    />
  );
};

YearSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholderText: PropTypes.string,
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
};

export default YearSelect;
