exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SanaForm_form-row{margin-bottom:20px}.SanaForm_form-row.SanaForm_row-actions{align-items:center;display:flex;flex-wrap:wrap;justify-content:space-between}.SanaForm_control{display:table;table-layout:fixed;width:100%}.SanaForm_label{display:table-cell;padding-right:3%;vertical-align:top;width:36%}.SanaForm_label,.SanaForm_radio-label{overflow:hidden;text-overflow:ellipsis}.SanaForm_radio-label{display:inline-block;width:100%}.SanaForm_validation{color:var(--theme-validation_Error_Color,#cb2245);font-size:var(--theme-text_SmallerSize,12px);line-height:1.2;margin-top:.33em}.SanaForm_validation .svg-inline--fa{margin-right:.5em}.SanaForm_captcha-row .SanaForm_control{display:flex}.SanaForm_captcha-row .SanaForm_field,.SanaForm_captcha-row .SanaForm_label{display:block}.SanaForm_captcha-row .SanaForm_label{padding-right:0}.SanaForm_btn{width:100%}@media (min-width:env(--theme-breakpoints_Small,600)px),print{.SanaForm_form-row.SanaForm_row-actions{padding-left:36%}.SanaForm_form-row.SanaForm_no-offset{padding-left:0}.SanaForm_btn{width:auto}}.SanaForm_stars{border:none;font-size:.85em;margin:0;padding:0}.SanaForm_stars path{stroke:#f7ad00;stroke-width:55px;fill:transparent}.SanaForm_stars .SanaForm_selected-star path{fill:#f7ad00}.SanaForm_stars svg{margin-right:5px}.SanaForm_fieldset{border:0;margin:0;min-inline-size:auto;min-width:0;padding:0}.SanaForm_fieldset legend{margin:0}.SanaForm_field,.SanaForm_read-only{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"form-row": "SanaForm_form-row",
	"formRow": "SanaForm_form-row",
	"row-actions": "SanaForm_row-actions",
	"rowActions": "SanaForm_row-actions",
	"control": "SanaForm_control",
	"label": "SanaForm_label",
	"radio-label": "SanaForm_radio-label",
	"radioLabel": "SanaForm_radio-label",
	"validation": "SanaForm_validation",
	"captcha-row": "SanaForm_captcha-row",
	"captchaRow": "SanaForm_captcha-row",
	"field": "SanaForm_field",
	"btn": "SanaForm_btn",
	"no-offset": "SanaForm_no-offset",
	"noOffset": "SanaForm_no-offset",
	"stars": "SanaForm_stars",
	"selected-star": "SanaForm_selected-star",
	"selectedStar": "SanaForm_selected-star",
	"fieldset": "SanaForm_fieldset",
	"read-only": "SanaForm_read-only",
	"readOnly": "SanaForm_read-only"
};