import PropTypes from 'prop-types';
import { FormGroup } from '../formGroups';
import { formGroupsMapping, fieldsMapping } from './editorsMapping';
import { createPropsFromSettings } from './createPropsFromSettings';
import { EntityTemplateFieldsContext } from './Context';
import { logger } from 'utils/logs';
import useEditableTemplateFields from './useEditableTemplateFields';
import { escapeFieldName } from './helpers';

const EntityTemplateFields = ({ fields, applyNamesEscaping, omitInitials }) => {
  const processedFields = useEditableTemplateFields(fields);

  return (
    <EntityTemplateFieldsContext.Provider value={processedFields}>
      {processedFields.map(({
        name: fieldName,
        title: fieldTitle,
        editor,
        settings,
        type,
        value: initialValue,
        ...formGroupProps
      }) => {
        formGroupProps = {
          fieldName: !applyNamesEscaping ? fieldName : escapeFieldName(fieldName),
          fieldTitle,
          initialValue: omitInitials || initialValue == null ? undefined : initialValue,
          ...createPropsFromSettings(settings),
          ...formGroupProps,
        };

        const FieldFormGroup = formGroupsMapping[editor];
        if (FieldFormGroup)
          return <FieldFormGroup key={fieldName} {...formGroupProps} />;

        const FieldComponent = fieldsMapping[editor];
        if (!FieldComponent) {
          logger.error(`FieldComponentGroup' or 'FieldComponent' is not found by name '${editor}' in 'editorsMapping'.`);
          return null;
        }

        return (
          <FormGroup
            key={fieldName}
            fieldComponent={FieldComponent}
            {...formGroupProps}
          />
        );
      })}
    </EntityTemplateFieldsContext.Provider>
  );
};

EntityTemplateFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    title: PropTypes.string,
    editor: PropTypes.string.isRequired,
    settings: PropTypes.object,
  })).isRequired,
  applyNamesEscaping: PropTypes.bool,
  omitInitials: PropTypes.bool,
};

export default EntityTemplateFields;
