exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TextArea_tar textarea{background:var(--theme-input_BackgroundColor,#f2f2f2);border:0;border-bottom-style:solid;border-bottom-width:1px;border-color:var(--theme-input_BottomBorderColor,#c2c2c2);border-radius:3px;box-sizing:border-box;color:var(--theme-input_Color,#333);display:block;height:5.77em;outline:none;padding:.3em 1em;resize:none;width:100%}.TextArea_tar textarea:focus{background-color:var(--theme-input_Focus_BackgroundColor,#fff);border-color:var(--theme-input_Focus_BottomBorderColor,#1f7bc9)}.TextArea_tar :-ms-input-placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic}.TextArea_tar ::placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic}.row-error .TextArea_tar textarea{background:var(--theme-input_Error_BackgroundColor,#fcf5f5);border-color:var(--theme-input_Error_BottomBorderColor,#ce8d9b);color:var(--theme-validation_Error_Color,#cb2245)}.row-error .TextArea_tar :-ms-input-placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic}.row-error .TextArea_tar ::placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic}", ""]);

// exports
exports.locals = {
	"tar": "TextArea_tar"
};