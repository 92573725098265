import { getIn } from 'formik';

const escapeMap = {
  '|': '||',
  '[': '|_',
  ']': '|-',
};
const escapeRegex = /[|[\]]/g;

export function escapeFieldName(text) {
  if (!text)
    return text;

  return text.replace(escapeRegex, match => escapeMap[match]);
}

export function extractEscapedTemplateFields(formData, templateFields) {
  const result = {};
  for (const field of templateFields) {
    result[field.name] = getIn(formData, escapeFieldName(field.name));
  }
  return result;
}
