import styles from './DateSelect.module.scss';
import { memo, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCultureName } from 'utils/hooks';
import {
  getYear,
  getMonthHuman,
  getDate,
} from '@wojtekmaj/date-utils';
import DaySelect from './DaySelect';
import MonthSelect from './MonthSelect';
import YearSelect from './YearSelect';
import { useSimpleTexts } from 'components/sanaText';
import { toLocaleDate } from 'utils/format';
import { defaultMinDate, defaultMaxDate } from '../datePicker/constants';
import { getMaxDay, getYearDiff } from '../datePicker/helpers';
import DateOnly from 'date-only';
import { pad } from 'utils/helpers';

const DateSelect = ({
  onChange,
  onKeyDown,
  onBlur,
  className = '',
  isValid = true,
  value,
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  'aria-invalid': ariaInvalid,
  ...props
}) => {
  const [dayPlaceholderText, monthPlaceholderText, yearPlaceholderText]
    = useSimpleTexts(['Day', 'Month', 'Year']).texts;

  const setRerenderState = useState()[1];
  const date = useRef();
  if (value) {
    const parsedValue = DateOnly.parse(value);
    if (parsedValue) {
      date.current = {
        day: getDate(parsedValue),
        month: getMonthHuman(parsedValue),
        year: getYear(parsedValue),
      };
    }
  } else {
    date.current = { day: null, month: null, year: null };
  }

  const onDateChange = useCallback(() => {
    let { day, month, year } = date.current;
    let value;

    const maxDay = getMaxDay(month, year);

    if (month) {
      if (month > 12)
        month = 12;
      else if (month < 1)
        month = 1;

      date.current.month = month;
      setRerenderState(Date.now);
    }

    if (day) {
      if (day > maxDay)
        day = maxDay;
      else if (day < 1)
        day = 1;

      date.current.day = day;
      setRerenderState(Date.now);
    }

    if (day && month && year)
      value = new Date(year, month - 1, day);
    else if (!day && !month && !year)
      value = null;
    else
      value = new Date(NaN);

    onChange(DateOnly.toISOString(value));
  }, [onChange]);

  const onDayChange = useCallback(value => {
    date.current.day = value;
    onDateChange();
  }, [onDateChange]);

  const onMonthChange = useCallback(value => {
    date.current.month = value;
    onDateChange();
  }, [onDateChange]);

  const culture = useCultureName();
  const yearDiff = getYearDiff(culture);
  const { minYear, maxYear } = useMinMaxYear(minDate, maxDate, yearDiff);

  const onYearChange = useCallback(value => {
    date.current.year = value - yearDiff;
    onDateChange();
  }, [onDateChange, yearDiff]);

  const format = useDateFormat(culture, yearDiff);
  const { day, month, year } = date.current;

  const selects = {
    day: (
      <DaySelect
        key="day"
        value={day}
        className={styles.select}
        placeholderText={dayPlaceholderText}
        month={month}
        year={year}
        isInvalid={!isValid}
        onChange={onDayChange}
        {...props}
      />
    ),
    month: (
      <MonthSelect
        key="month"
        value={month}
        className={styles.select}
        placeholderText={monthPlaceholderText}
        year={year}
        isInvalid={!isValid}
        onChange={onMonthChange}
        {...props}
      />
    ),
    year: (
      <YearSelect
        key="year"
        value={year + yearDiff}
        className={styles.select}
        placeholderText={yearPlaceholderText}
        minYear={minYear}
        maxYear={maxYear}
        isInvalid={!isValid}
        onChange={onYearChange}
        {...props}
      />
    ),
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <input
        type="date"
        defaultValue={year && month && day ? `${year}-${pad(month, 2)}-${pad(day, 2)}` : ''}
        className="visually-hidden"
        aria-hidden
        name={props.name}
        id={props.id}
        onFocus={focusToFirstSelect}
      />
      {format.map(part => selects[part])}
    </div>
  );

  function focusToFirstSelect() {
    if (!props.id)
      return;

    const id = `${props.id}_${format[0]}`;
    document.getElementById(id).focus();
  }
};

DateSelect.propTypes = {
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  isValid: PropTypes.bool,
  value: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default memo(DateSelect);

function useDateFormat(culture, yearDiff) {
  return useMemo(() => {
    const formatRegex = /year|month|day/g;

    const year = 2017;
    const monthIndex = 11;
    const day = 18;

    return toLocaleDate(new Date(year, monthIndex, day), culture)
      .replace(day, 'day')
      .replace(monthIndex + 1, 'month')
      .replace(year + yearDiff, 'year')
      .match(formatRegex);
  }, [culture, yearDiff]);
}

function useMinMaxYear(minDate, maxDate, yearDiff) {
  return useMemo(() => {
    const minYear = getYear(minDate) + yearDiff;
    const maxYear = getYear(maxDate) + yearDiff;
    return { minYear, maxYear };
  }, [minDate, maxDate, yearDiff]);
}
